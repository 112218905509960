<template>
  <div class="billAll-container">
      <div class="billAll-box">
          <div class="billAll-box-middle">
              <div class="billAll-title">账单总览</div>
              <div class="billAll-chose">
                  <div>
                      <div>城市代理人手机号</div>
                        <el-select v-model="number" placeholder="请选择手机号" class="select">
                            <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                  </div>
                  <div>
                      <div class="date">日期</div>
                        <div class="block">
                            <span class="demonstration"></span>
                            <el-date-picker
                            v-model="value"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="选择日期">
                            </el-date-picker>
                        </div>
                  </div>
                  <div class="billAll-serch" @click="search">查询</div>
              </div>
              <div class="billAll-list">
                  <div class="billAll-list-title">
                      <div>手机号</div>
                      <div>结算期</div>
                      <div>发生（申报）时间</div>
                      <div>结算订单数</div>
                      <div>结算订单金额</div>
                      <div>结算佣金</div>
                      <div>操作</div>
                  </div>
                  <div>
                      <div v-for="(v,k) in list" :key="k" class="billAll-list-detail">
                          <div>{{v.currentUserTel}}</div>
                          <div>{{v.yearmonth}}</div>
                         <div>{{v.declareTime}}</div>
                         <div>{{v.statementOrderNum}}</div>
                         <div>{{v.statementOrderAmt}}</div>
                          <div>{{v.statementIncome}}</div>
                         <div><span @click="watchBill(v.cpimsCode,v.currentUserTel,v.yearmonth,v.declareTime,v.statementOrderNum,v.statementOrderAmt,v.statementIncome)">查看详情</span></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      

    </div>
</template>

<script>
export default {
  name: 'billAll',
  data(){
    return{
      number:'',
      options:[],
      value:'',
      list:[]
    }
  },
  watch:{
       
  },
  components: {

  },
  
  mounted(){
      this.getPhone()
       this.search(1)
  },
  methods:{
     watchBill(val1,val2,val3,val4,val5,val6,val7){
         this.$router.push({
             path:'/cityAgency/billDetail',
             query:{
                 id:val1,
                 telephone:val2,
                 time1:val3,
                 time2:val4,
                 num:val5,
                 amt:val6,
                 income:val7
             }
         })
     },
     getPhone(){
         this.$http.post(this.GLOBAL.new_url+'/cityproxy/admin/statement/selector',{
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                //   console.log(res)
              if(res.data.code == "200"){
                  this.options = res.data.data
              }
            })
     },

     search(val){
         if(val == 1){
              var date = new Date;
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            
            if(month<10){
                month = '0'+month
            }
            
            this.number = this.$cookie.getCookie('username')
            this.value = year + '-'+month
            // console.log(this.value,this.number)
          }
         if(this.number != ''&&this.value!=''){
              this.$http.post(this.GLOBAL.new_url+'/cityproxy/admin/statement/list',{
                userTel:this.number,
                yearMonth:this.value
                },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
                                    //   console.log(res)
                if(res.data.code == "200"){
                    this.list = res.data.data.list
                    this.list.map(x=>{
                      x.currentUserTel = x.currentUserTel.substr(0, 3) + '****' + x.currentUserTel.substr(7)
                  })
                }
            })
          }
     }
  }
}

</script>

<style scoped>
    .billAll-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        background-color: #fff;
        padding-top: 12px;
        min-height: 950px;
        box-sizing: border-box;
    }
    .billAll-box-middle{
        width: 885px;
        margin: 0 auto;
        padding-bottom: 60px;
    }
    .billAll-box-middle>div{
        padding-left: 4px;
    }
    .billAll-title{
        height: 32px;
        border-bottom: 1px solid #d8d8d8;
        font-size: 16px;
        margin-bottom: 25px;
    }
    .billAll-chose{
        height: 66px;
        margin-bottom: 32px;
    }
    .billAll-chose>div{
        float: left;
        margin-right: 125px;
    }
    .date{
        margin-bottom: 12px;
    }
    .select{
        margin-top: 12px;
        font-size: 14px;
        width: 230px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 3px;
    }
    .billAll-serch{
        width: 80px;
        height: 32px;
        font-size: 14px;
        background: #EB5E00;
        border-radius: 3px;
        text-align: center;
        line-height: 32px;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 34px;
        margin-right: 0!important;
    }
    .billAll-list{
        width: 882px;
        border:1px solid #EEECEC;
        border-bottom: none;
        padding-left: 0!important;
    }
    .billAll-list-title{
        
        height: 53px;
        background: #F8F8F8;
    }
    .billAll-list-title>div{
        font-size: 16px;
        float: left;
        height: 53px;
        line-height: 53px;
        width: 120px;
        text-align: center;
    }
    .billAll-list-title>div:nth-of-type(3){
        width:162px;
    }
    .billAll-list-detail{
        height: 59px;
        border-bottom:1px solid #EEECEC;
    }
    .billAll-list-detail>div{
        font-size: 14px;
        float: left;
        height: 59px;
        line-height: 59px;
        width: 117px;
        text-align: center;
    }
   .billAll-list-detail>div:nth-of-type(3){
        width:175px;
    }
    .billAll-list-detail>div>span{
        color: #EB5E00;
        text-decoration: underline;
        cursor: pointer;
    }
</style>
<style>
    .el-select-dropdown__item.selected {
    color: #606266;
    font-weight: 400;
}
.el-input__icon{
    height: 40px;
}
.el-select .el-input.is-focus .el-input__inner{
    border-color: #DCDFE6;
}
.el-select .el-input__inner:focus{
    border-color: #DCDFE6;
}
</style>

